import {
  BTN_CLEAR,
  BTN_CONTACTS,
  BTN_FILES,
  BTN_IMAGES,
  BTN_INTERNAL_LINKS,
  BTN_SELF_SERVICE,
  BTN_SNIPPETS,
} from './toolbar'
import { ALLOWED_IMAGE_EXTENSIONS } from './utils'

export function registerCustomCommands(FroalaEditor: any) {
  FroalaEditor.RegisterCommand(BTN_IMAGES, {
    callback() {
      chooseFile((files) => this.kundoFroala.insertImage(files), ALLOWED_IMAGE_EXTENSIONS)
    },
    icon: BTN_IMAGES,
    title: TRANSLATIONS.insert_image,
  })

  FroalaEditor.RegisterCommand(BTN_FILES, {
    callback() {
      chooseFile((files) => this.kundoFroala.attachFile(files))
    },
    icon: BTN_FILES,
    title: TRANSLATIONS.attach_files,
  })

  FroalaEditor.RegisterCommand(BTN_SNIPPETS, {
    callback() {
      this.kundoFroala.toggleSnippetWidget()
    },
    icon: BTN_SNIPPETS,
    title: TRANSLATIONS.reply_snippet,
  })
  FroalaEditor.RegisterShortcut('E'.charCodeAt(0), BTN_SNIPPETS, null, 'E')

  FroalaEditor.RegisterCommand(BTN_CLEAR, {
    callback() {
      const thisText = this.selection.text()
      this.selection.remove()
      this.html.insert(thisText)
    },
    icon: BTN_CLEAR,
    title: TRANSLATIONS.clear_formatting,
  })

  FroalaEditor.RegisterCommand(BTN_CONTACTS, {
    callback() {
      this.link.insert('#contactModal', TRANSLATIONS.kundo_contact_modal_linktext)
    },
    title: TRANSLATIONS.kundo_contact_modal_title,
  })

  FroalaEditor.RegisterCommand(BTN_SELF_SERVICE, {
    callback() {
      this.kundoFroala.toggleSelfServiceWidget()
    },
    icon: BTN_SELF_SERVICE,
  })
  FroalaEditor.RegisterShortcut('E'.charCodeAt(0), BTN_SNIPPETS, null, 'E')
}

export function registerInternalLinkCommand(FroalaEditor: any, title: string) {
  // This is wrapped in a callback since we want a dynamic toolbar button tooltip title
  FroalaEditor.RegisterCommand(BTN_INTERNAL_LINKS, {
    callback() {
      const $tb = this.$tb
      const $btn = $tb.find(`.fr-command[data-cmd="${BTN_INTERNAL_LINKS}"]`)
      const btnOffset = $btn.offset()
      const tbOffset = $tb.offset()
      const left = btnOffset.left - tbOffset.left
      const top = btnOffset.top - tbOffset.top + $tb.outerHeight() + 10
      const kundoFroala = this.kundoFroala
      if (kundoFroala.internalLinkWidget) {
        kundoFroala.internalLinkWidget.box.css('top', top).css('left', left)
        kundoFroala.toggleInternalLinkWidget()
      }
    },
    icon: BTN_INTERNAL_LINKS,
    title,
  })
}

function chooseFile(
  callback: (files: FileList | null) => void,
  acceptExtensions?: string[],
  multiple = true,
) {
  const input = $('<input type="file">')
  if (acceptExtensions) {
    input.attr('accept', acceptExtensions.map((s) => '.' + s).join(','))
  }
  if (multiple) {
    input.prop('multiple', true)
  }
  const elm = input[0] as HTMLInputElement
  // The input field need to be attached the DOM to allow uploads in iOS Safari
  // Reference: https://stackoverflow.com/a/47665517/461559
  $(document.body).append(input)
  input.on('change', () => {
    callback(elm.files)
    input.remove()
  })
  input.trigger('click')
  input.hide()
}
