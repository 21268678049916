export const ALLOWED_IMAGE_EXTENSIONS = ['png', 'jpeg', 'jpg', 'gif']
export const isAllowedImageMime = (type: string) => {
  const exts = ALLOWED_IMAGE_EXTENSIONS
  for (let i = 0, len = exts.length; i < len; i++) {
    if (type === 'image/' + exts[i]) return true
  }
  return false
}

/*
 * Try to find any images in the event data, and
 * normalize data access between "paste" and "drop" events.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/items
 **/
export function getFilesFromEvent(event: any) {
  event = event.originalEvent || event
  const data: DataTransfer = event.dataTransfer || event.clipboardData
  if (!data) {
    return []
  }
  if (data.types?.includes('text/html')) {
    return []
  }
  // Using .files since .items is not supported in IE11
  if (data?.files?.length) {
    return data.files
  }
  if (data?.items?.length) {
    const items: DataTransferItemList = data.items
    return Array.from(items)
      .map((item) => item.getAsFile())
      .filter((item) => item)
  }
  return []
}
