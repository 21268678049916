/*
 * Handles the toggling of mail conversation history within the text editor
 * The textfield is prefilled with correct markup from django forms
 **/
export function froalaMailHistory(FroalaEditor: any) {
  FroalaEditor.PLUGINS.MailHistoryPlugin = function (editor: any) {
    return {
      _init() {
        const historyContent = '[data-froala-role=history-content]'
        editor.$el.find(historyContent).css('display', 'none')

        editor.$el.on('click', '[data-froala-role=history-toggle-btn]', () => {
          if (editor.$el.find(historyContent).css('display') === 'none') {
            editor.$el.find(historyContent).show()
          } else if (editor.$el.find(historyContent).css('display') === 'block') {
            editor.$el.find(historyContent).hide()
          }
        })
      },
    }
  }
}
