export function froalaFixPasteBug(FroalaEditor: any) {
  /*
   * Fix froala adding `id="isPasted"` to pasted content, potentially causing accessibility issues
   * See: https://github.com/froala/wysiwyg-editor/issues/4328
   */
  if (!FroalaEditor.PLUGINS) {
    return
  }
  FroalaEditor.PLUGINS['fixPasteBug'] = function (editor: any) {
    return {
      _init() {
        editor.events.on('paste.after', () => {
          const t = document.createElement('template')
          t.innerHTML = editor.html.get()
          const offender = t.content.querySelector('#isPasted')
          if (offender) {
            editor.selection.save()
            offender.removeAttribute('id')
            editor.html.set(t.innerHTML)
            editor.selection.restore()
          }
        })
      },
    }
  }
}
