import { BTN_EMOJIS } from '../toolbar'

export function froalaEmoji(FroalaEditor: any) {
  // Emojis
  FroalaEditor.RegisterCommand(BTN_EMOJIS, {
    callback: function () {
      if (this.popups.isVisible(BTN_EMOJIS)) {
        if (this.$el.find('.fr-marker')) {
          this.events.disableBlur()
          this.selection.restore()
        }
        this.emojis.hidePopup()
      } else {
        this.emojis.showPopup()
      }
    },
    focus: true,
    plugin: BTN_EMOJIS,
    popup: true,
    title: 'Emojis',
    undo: true,
  })
  FroalaEditor.POPUP_TEMPLATES[BTN_EMOJIS] = '[_CUSTOM_LAYER_]'
  FroalaEditor.PLUGINS[BTN_EMOJIS] = function (froala: any) {
    const { popups } = froala

    function initPopup() {
      const { EmojiPicker } = window.KUNDO
      if (!EmojiPicker) {
        console.error('EmojiPicker not loaded, aborting.')
        return
      }
      // Add an container with the correct width as a placeholder for correct positioning
      const popupHTML = '<div class="emojis-root" style="width: 338px"></div>'
      const popup = popups.create(BTN_EMOJIS, { custom_layer: popupHTML })

      // NOTE: Due to limitations in Froalas accessibility plugin,
      // tab navigation doesn't work inside the EmojiPicker.
      EmojiPicker(popup.find('.emojis-root')[0], {
        onSelect: (emoji) => {
          froala.html.insert(emoji)
          hidePopup()
        },
      })
    }

    function showPopup() {
      if (!popups.get(BTN_EMOJIS)) {
        initPopup()
      }
      popups.setContainer(BTN_EMOJIS, froala.$tb)

      // Position the toolbar next to the button
      const $btn = froala.$tb.find(`.fr-command[data-cmd="${BTN_EMOJIS}"]`)
      const left = $btn.offset().left + $btn.outerWidth() / 2
      const top = $btn.offset().top + (froala.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10)
      popups.show(BTN_EMOJIS, left, top, $btn.outerHeight())
    }

    function hidePopup() {
      popups.hide(BTN_EMOJIS)
    }

    return { hidePopup, showPopup }
  }
}
