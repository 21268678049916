import React, { useState, useEffect } from 'react'
import SuggestionCardList from './SuggestionCardList'
import { SuggestionCardProps } from './SuggestionCard'
import DebouncedTextInput from './DebouncedTextInput'
import useApi, { HTTP, StateType, endpoints } from '../hooks/useApi'

type KeywordSearchProps = {
  dialogSlug: string
  inboxSlug: string
  mailCommentId: number | null
  onRequestInsertLink: (title: string, url: string) => void
  onRequestSmartReply: (url: string) => void
  searchHits: SuggestionCardProps[] | null
  setSearchHits: (searchHits: SuggestionCardProps[] | null) => void
}

enum SearchStatus {
  Idle,
  Loading,
  Loaded,
  Error,
}

enum ErrorCode {
  ServerError = 1,
  LastMessageIsOutgoing = 2,
  ContentLengthExceeded = 3,
}

type Error = {
  code: ErrorCode
}

export default function KeywordSearch(props: KeywordSearchProps) {
  const {
    dialogSlug,
    inboxSlug,
    mailCommentId,
    onRequestInsertLink,
    onRequestSmartReply,
    searchHits,
    setSearchHits,
  } = props
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [status, setStatus] = useState<SearchStatus>(SearchStatus.Idle)
  const [error, setError] = useState<Error>()

  const string_length_search_threshold = 2

  const [GETTextSearchStatus, GETTextSearch] = useApi<{
    searchTerm: string
    suggestions: SuggestionCardProps[]
    error: Error | null
  }>(HTTP.GET, endpoints.ai.keywordSearch(inboxSlug, dialogSlug, searchTerm, mailCommentId))

  useEffect(() => {
    if (GETTextSearchStatus.type === StateType.Done) {
      if (GETTextSearchStatus.payload.error) {
        setStatus(SearchStatus.Error)
        setError(GETTextSearchStatus.payload.error)
      } else {
        setStatus(SearchStatus.Loaded)
        setSearchHits(GETTextSearchStatus.payload.suggestions)
      }
    } else if (GETTextSearchStatus.type === StateType.Loading) {
      setStatus(SearchStatus.Loading)
    } else if (GETTextSearchStatus.type === StateType.Error) {
      setStatus(SearchStatus.Error)
      setError({ code: ErrorCode.ServerError })
    }
  }, [GETTextSearchStatus])

  useEffect(() => {
    if (searchTerm.length == 0) {
      setSearchHits(null)
    } else if (searchTerm.length > string_length_search_threshold) {
      GETTextSearch()
    }
  }, [searchTerm])

  const submitSearchForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchTerm(event.target[0].value)
  }

  return (
    <div className="text-search">
      <div className="text-search--container">
        <form role="search" className="text-search--search-bar" onSubmit={submitSearchForm}>
          <DebouncedTextInput
            onNewValue={setSearchTerm}
            placeholder={gettext('Sök')}
            resetButtonLabel={gettext('Rensa sökfält')}
            debounceWaitTime={300}
          />
        </form>

        {status == SearchStatus.Loaded &&
          searchTerm.length > 0 &&
          searchHits &&
          searchHits.length < 1 && <p>{gettext('Inga träffar matchar din sökning')}</p>}

        {searchHits && searchHits.length > 0 && (
          <SuggestionCardList
            suggestions={searchHits}
            onRequestSmartReply={onRequestSmartReply}
            onRequestInsertLink={onRequestInsertLink}
          />
        )}
      </div>
    </div>
  )
}
