import React, { useEffect, useState } from 'react'
import useApi, { HTTP, StateType, endpoints } from '../hooks/useApi'
import { ReactComponent as IconTwinkle } from '@icons/twinkle.svg'
import { ReactComponent as IconQuestionCircle } from '@icons/question-circle.svg'
import SuggestionCardList from './SuggestionCardList'
import { SuggestionCardProps } from './SuggestionCard'

type SmartSuggestionProps = {
  dialogSlug: string
  hasInboxEditPermissions: boolean
  hasSmartReplyChannels: boolean
  inboxSlug: string
  isConnectedToSmartReplyChannel: boolean
  mailCommentId: number | null
  onRequestInsertLink: (title: string, url: string) => void
  onRequestSmartReply: (url: string) => void
  setSuggestions: (suggestions: SuggestionCardProps[]) => void
  suggestions: SuggestionCardProps[] | null
  suggestionsLoadedCallback: (suggestionsCount: number) => void
}

enum SmartSuggestionsStatus {
  Loading,
  Loaded,
  Error,
}

enum ErrorCode {
  ServerError = 1,
  LastMessageIsOutgoing = 2,
  ContentLengthExceeded = 3,
}

type Error = {
  code: ErrorCode
}

export default function SmartSuggestions(props: SmartSuggestionProps) {
  const {
    dialogSlug,
    hasInboxEditPermissions,
    hasSmartReplyChannels,
    inboxSlug,
    isConnectedToSmartReplyChannel,
    mailCommentId,
    onRequestInsertLink,
    onRequestSmartReply,
    setSuggestions,
    suggestions,
    suggestionsLoadedCallback,
  } = props
  const [searchTerm, setSearchTerm] = useState<string>()
  const [status, setStatus] = useState<SmartSuggestionsStatus>(SmartSuggestionsStatus.Loaded)
  const [error, setError] = useState<Error>()
  const [GETSmartSuggestionsStatus, GETSmartSuggestions] = useApi<{
    searchTerm: string
    suggestions: SuggestionCardProps[]
    error: Error | null
  }>(HTTP.GET, endpoints.ai.smartSuggestions(inboxSlug, dialogSlug, mailCommentId))

  useEffect(() => {
    if (!hasSmartReplyChannels || !isConnectedToSmartReplyChannel) {
      return
    }

    if (suggestions === null) {
      GETSmartSuggestions()
    }
  }, [])

  useEffect(() => {
    if (GETSmartSuggestionsStatus.type === StateType.Done) {
      if (GETSmartSuggestionsStatus.payload.error) {
        setStatus(SmartSuggestionsStatus.Error)
        setError(GETSmartSuggestionsStatus.payload.error)
      } else {
        setStatus(SmartSuggestionsStatus.Loaded)
        setSuggestions(GETSmartSuggestionsStatus.payload.suggestions)
        setSearchTerm(GETSmartSuggestionsStatus.payload.searchTerm)
        suggestionsLoadedCallback(GETSmartSuggestionsStatus.payload.suggestions.length)
      }
    } else if (GETSmartSuggestionsStatus.type === StateType.Error) {
      setStatus(SmartSuggestionsStatus.Error)
      setError({ code: ErrorCode.ServerError })
    }
  }, [GETSmartSuggestionsStatus])

  const getAiContactFormUrl = (lang: string | null) => {
    switch (lang) {
      case 'sv':
        return 'https://www.kundo.se/kontaktformular'
      case 'fi':
        return 'https://www.kundo.fi/ota-yhteytta-kundoon'
      case 'no':
        return 'https://www.kundo.no/kontakt-ai'
      case 'en':
        return 'https://www.kundo.se/en/contact-ai'
      default:
        return 'https://www.kundo.se/en/contact-ai'
    }
  }

  const smartSuggestionsHelpURL =
    window.CURRENT_LANGUAGE == 'sv'
      ? 'https://www.kundo.se/hjalp-support?path=%2Fguide%2Fsmart-suggestions-and-smart-replies'
      : 'https://www.kundo.se/en/help-support?path=%2Fguide%2Fsmart-suggestions-and-smart-replies'

  return (
    <>
      <div className="smart-suggestions" data-search-term={searchTerm}>
        <div className="smart-suggestions--header">
          <h2>
            <span className="icon">
              <IconTwinkle></IconTwinkle>
            </span>
            <span className="text">Smart Suggestions</span>
          </h2>
          <a
            className="smart-suggestions--help-link"
            href={smartSuggestionsHelpURL}
            target="_blank"
            rel="nofollow noopener"
          >
            <IconQuestionCircle></IconQuestionCircle>
          </a>
        </div>
        <div className="smart-suggestions--container">
          {status === SmartSuggestionsStatus.Loading && (
            <span className="skeleton-box" style={{ width: '100%' }}></span>
          )}

          {error && error.code == ErrorCode.LastMessageIsOutgoing && (
            <p>{gettext('Senaste konversationen är utgående.')}</p>
          )}
          {error && error.code == ErrorCode.ServerError && (
            <p>{gettext('Tekniskt fel, försök igen senare.')}</p>
          )}

          {hasSmartReplyChannels && !isConnectedToSmartReplyChannel && (
            <>
              <p>
                {gettext(
                  'För att få Smart Suggestions måste du koppla inkorgen till en self-service-kanal.',
                )}
              </p>
              {hasInboxEditPermissions && (
                <a
                  className="btn btn--editor btn--secondary btn--small"
                  href={`${window.location.origin}/inbox/${inboxSlug}/settings/smart-suggestions/`}
                >
                  {gettext('Koppla din inkorg')}
                </a>
              )}
            </>
          )}

          {!hasSmartReplyChannels && (
            <>
              <p>
                {gettext(
                  'Effektivisera ditt arbete genom att låta vår AI skriva ett svar på mailet. Vill du veta mer?',
                )}
              </p>
              <a
                href={getAiContactFormUrl(window.CURRENT_LANGUAGE)}
                target="_blank"
                className="btn btn--editor btn--secondary btn--small"
              >
                {gettext('Anmäl intresse')}
              </a>
            </>
          )}

          {suggestions && suggestions.length === 0 && isConnectedToSmartReplyChannel && (
            <p>{gettext('Det finns inga relevanta förslag. Prova att använda sökfunktionen.')}</p>
          )}

          {suggestions && suggestions.length > 0 && (
            <SuggestionCardList
              suggestions={suggestions}
              onRequestSmartReply={onRequestSmartReply}
              onRequestInsertLink={onRequestInsertLink}
            />
          )}
        </div>
      </div>
    </>
  )
}
