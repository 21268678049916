import Tribute from 'tributejs'

interface MentionedUser {
  avatar_background_color: string
  avatar_url: string
  email: string
  id: number
  name: string
}

export function setupMentions(froala: any, container: HTMLElement): Tribute<MentionedUser> {
  const tribute = new Tribute<MentionedUser>({
    allowSpaces: true,
    fillAttr: 'name',
    lookup(user, _text: string) {
      return user.name + user.email
    },
    menuContainer: container,
    menuItemTemplate({ original }) {
      const { avatar_background_color, avatar_url, name } = original
      const avatar = $('<img>')
        .attr({
          height: 24,
          src: avatar_url,
          style: 'background-color:' + avatar_background_color,
          width: 24,
        })
        .prop('outerHTML')
      const displayName = $('<span class="">').text(name).prop('outerHTML')

      return avatar + displayName
    },
    noMatchTemplate() {
      return $('<li class="no-match"><span>')
        .text(gettext('Hittar inga användare'))
        .prop('outerHTML')
    },
    positionMenu: false,
    selectTemplate({ original }) {
      const { id, name } = original
      return $('<span class="fr-deletable fr-tribute mention" contenteditable="false">')
        .attr('data-editor-id', id)
        .text('@' + name)
        .prop('outerHTML')
    },
    values: [],
  })

  froala.events.on(
    'keydown',
    function (e: any) {
      const enterKeyCode = 13
      if (e.which === enterKeyCode && tribute.isActive) {
        return false
      }
    },
    true,
  )

  tribute.attach(froala.el)

  return tribute
}
