import IconX from '@icons/x.svg'
import getFiletypeFromName from './get-filetype-from-name'

export type File = {
  filename: string
  path: string
  upload_puri?: string
  url: string
}

export class NullAttachmentsList {
  show_progress_error() {}

  show_error_msg(msg) {
    alert(msg)
  }

  show_progress(progress) {}

  hide_progress(progress) {}

  refresh_attachments() {}

  get_files() {
    throw Error('Should be unused!')
  }

  add_file(file) {}

  has_file() {}

  remove_file(file_id) {}

  remove_file_by_url(url) {}
}

export class LargeAttachmentsList {
  attachments: JQuery<HTMLElement>
  json_field: JQuery<HTMLElement>
  attachment_list: JQuery<HTMLUListElement>
  label: JQuery<HTMLElement>
  onChange?: (files: File[]) => void

  constructor(elm: JQuery<HTMLElement>, onChange?: (files: File[]) => void) {
    this.attachments = elm
    this.json_field = elm.find('.fn-attachment-list')
    this.attachment_list = elm.find('ul')
    this.label = elm.parent().find(`label[for="${this.json_field.attr('id')}"]`)
    this.onChange = onChange

    const self = this
    this.attachments.on('click', 'button.fn-large-attachment-list__remove-button', function (e) {
      e.preventDefault()
      self.remove_file($(this).data('id'))

      const frame = $('#mail-iframe', window.parent.document)
      frame.length > 0 && frame.height(document.body.scrollHeight)
    })
  }

  refresh_attachments() {
    this.attachments.find('.fn-errors').remove()
    this.attachments.find('li').remove()

    const elms = this.get_files().map(this._file_to_element)
    this.attachment_list.append(elms)

    if (this.attachments.find('li').length) {
      this.label.show()
    } else {
      this.label.hide()
    }
  }

  _file_to_element(file) {
    const fileName = file.filename || file.path.replace(/^.*[\\/]/, '')
    const fileType = getFiletypeFromName(fileName)
    const button = $(
      '<button class="btn btn--small btn--secondary btn--only-icon fn-large-attachment-list__remove-button">',
    )
      .append(IconX)
      .append($('<span class="visuallyhidden">').text(TRANSLATIONS.remove))
    button.data('id', file.url || file.path)
    const nameLink = $(
      '<a target="_blank" rel="noopener noreferrer" class="large-attachment-list__filename">',
    )
    nameLink.text(fileName)
    nameLink.attr('href', file.url)
    const li = $('<li class="large-attachment-list__item">')

    let iconClass = 'large-attachment-list__icon'
    if (fileType) {
      iconClass += ` large-attachment-list__icon--${fileType}`
    }
    const icon = $(`<span class="${iconClass}">`)
    if (fileType === 'image') {
      const img = $('<img>')
      img.attr('src', file.url)
      icon.append(img)
    }
    li.append(icon)
    li.append(nameLink)
    li.append(button)
    return li
  }

  show_error_msg(msg) {
    this.show_progress_error()
    this.attachments.find('.fn-errors').remove()
    this.attachments
      .find('.fn-progress-bar')
      .before($(`<div class="fn-errors field-error-msg error-msg" aria-live="polite">${msg}</div>`))
  }

  add_progress_bar(disposition = 'attachment') {
    this.attachments.find('.fn-progress-bar').parent('li').remove()
    this.attachment_list.append(
      '<li><div class="fn-progress-bar progress-bar"><div class="progress-bar__progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><li>',
    )

    if (disposition === 'attachment') {
      const attachmentsListElement = this.attachments.get(0)
      const bounding = attachmentsListElement.getBoundingClientRect()
      if (!(bounding.top >= 0 && bounding.bottom <= window.innerHeight)) {
        attachmentsListElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  show_progress_error() {
    this.add_progress_bar()
    const progressBar = this.attachments.find('.fn-progress-bar')
    progressBar.find('.progress-bar__progress').css('width', '100%')
    progressBar.addClass('progress-bar--error')
    progressBar.show()
    this.attachments.show()
  }

  show_progress(progress, disposition = 'attachment') {
    this.attachments.show()
    this.attachments.find('.fn-errors').remove()
    let progressBar = this.attachments.find('.fn-progress-bar')

    if (!progressBar.length) {
      this.add_progress_bar(disposition)
      progressBar = this.attachments.find('.fn-progress-bar')
    }

    progressBar
      .find('.progress-bar__progress')
      .css('width', `${progress}%`)
      .attr('aria-valuenow', `${Math.round(progress)}`)
    progressBar.removeClass('progress-bar--error')
    progressBar.show()
  }

  hide_progress(progress) {
    this.attachments.find('.fn-progress-bar').parent('li').remove()
  }

  add_file(file) {
    // path is expected by knowledge
    // url and filename is expected by new style attachment verification server side
    const files = this.get_files()
    files.push({
      filename: file.filename,
      path: file.path,
      url: file.url || file.temporary_url,
    })
    this.set_files(files)
  }

  has_file(file: File) {
    return this.get_files().filter((f) => f.url === file.url).length > 0
  }

  remove_file(url: string) {
    const json = this.get_files().filter((file) => file.url !== url)
    this.set_files(json)
  }

  remove_file_by_url(url: string) {
    const json = this.get_files().filter((file) => file.url !== url)
    this.set_files(json)
  }

  get_files(): File[] {
    return JSON.parse((this.json_field.val() as string) || '[]') || []
  }

  set_files(files?: File[]) {
    files = files || []
    this.json_field.val(JSON.stringify(files)).trigger('change')
    this.refresh_attachments()
    if (this.onChange) {
      this.onChange(files)
    }
  }
}
