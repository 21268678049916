import initSelfServiceWidget from '../editors/self-service-widget'

type OnSelectHandler = () => void

type insertLinkFunction = (url: string, text: string) => void
type insertHTMLFunction = (html: string) => void
type froalaEditOnFunction = () => void
type froalaEditOffFunction = () => void
type setEditableFunction = (state: boolean) => void

export default class SelfServiceWidget {
  aiToolbarButton: HTMLElement
  box: JQuery<HTMLElement>
  badge: JQuery<HTMLElement>

  onclose: (focus: boolean) => void
  onselect: OnSelectHandler
  froalaEditOnFunction: () => void
  froalaEditOffFunction: () => void

  constructor(options: {
    parent: HTMLElement
    insertLinkFunction: insertLinkFunction
    insertHTMLFunction: insertHTMLFunction
    froalaEditOnFunction: froalaEditOnFunction
    froalaEditOffFunction: froalaEditOffFunction
    onselect: OnSelectHandler
    onclose?: (focus: boolean) => void
    positionAbove?: boolean
    aiToolbarButton: HTMLElement
    isConnectedToSmartReplyChannel: boolean
    hasSmartReplyChannels: boolean
    hasInboxEditPermissions: boolean
  }) {
    this.renderInitial(
      options.positionAbove,
      options.insertLinkFunction,
      options.insertHTMLFunction,
      this.setEditableFunction,
      options.isConnectedToSmartReplyChannel,
      options.hasSmartReplyChannels,
      options.hasInboxEditPermissions,
    )
    this.box.appendTo(options.parent)
    this.aiToolbarButton = options.aiToolbarButton
    this.badge = $('<div class="badge badge--emerald badge--found-guides hidden"></div>')
    this.badge.appendTo($(options.aiToolbarButton).find('.ai-fr-toolbar-btn-content'))

    this.onselect = options.onselect || function () {}
    this.onclose = options.onclose || function () {}
    this.froalaEditOnFunction = options.froalaEditOnFunction
    this.froalaEditOffFunction = options.froalaEditOffFunction

    $('body').on('click', () => this.close(false))
    this.box.on('click', (e) => {
      e.stopPropagation()
    })
  }

  renderInitial(
    positionAbove?: boolean,
    insertLinkFunction?: insertLinkFunction,
    insertHTMLFunction?: insertHTMLFunction,
    setEditableFunction?: setEditableFunction,
    isConnectedToSmartReplyChannel: boolean = false,
    hasSmartReplyChannels: boolean = false,
    hasInboxEditPermissions: boolean = false,
  ) {
    this.box = $(`<div class="hidden self-service-widget-box">`).addClass(
      positionAbove ? 'self-service-widget-box--above' : 'self-service-widget-box--below',
    )
    initSelfServiceWidget(
      this.box.get(0),
      $('#comment-form').data('inbox-slug'),
      $('#comment-form').data('dialog-slug'),
      $('#comment-form').data('mail-comment-id'),
      this.suggestionsLoadedCallback,
      this.close.bind(this),
      insertLinkFunction,
      insertHTMLFunction,
      setEditableFunction,
      isConnectedToSmartReplyChannel,
      hasSmartReplyChannels,
      hasInboxEditPermissions,
    )
  }

  open() {
    let boxLeftOffset = this.aiToolbarButton.offsetLeft - (this.box.width() || 0) / 2
    if (boxLeftOffset < 0) {
      boxLeftOffset = 0
    }
    this.box.css({ left: boxLeftOffset })
    this.box.removeClass('hidden')
    return this
  }

  close(focus = true) {
    this.box.addClass('hidden')
    this.onclose(focus)
    return this
  }

  toggle() {
    if (this.box.is('.hidden')) {
      return this.open()
    } else {
      return this.close()
    }
  }

  destroy() {
    this.box.remove()
  }

  suggestionsLoadedCallback = (suggestionsCount: number) => {
    if (suggestionsCount === 0) return
    // TODO: translation is missing '%s' so we have to create the string manually for now
    const badgeText =
      String(suggestionsCount) + ' ' + ngettext('förslag', 'förslag', suggestionsCount)
    this.badge.text(badgeText)
    this.badge.removeClass('hidden')
    this.aiToolbarButton.classList.add('ai-colored')
  }

  setEditableFunction = (state: boolean) => {
    if (state) {
      this.froalaEditOnFunction()
    } else {
      this.froalaEditOffFunction()
    }
  }
}
