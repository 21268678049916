import { FroalaEditor as FroalaEditorStatic } from './froala-2'

import { froalaEmoji } from './plugins/emoji'
import { froalaFixPasteBug } from './plugins/fix-paste-bug'
import { froalaMailHistory } from './plugins/mail-history'

import { registerCustomCommands } from './commands'
import { defineCustomIcons } from './toolbar'
import { Provider, VIDEO_PROVIDERS } from './video'

let FroalaEditor: null | Froala = null

export type Froala = {
  ENTER_BR: number
  ENTER_DIV: number
  ENTER_P: number
  ICON_DEFAULT_TEMPLATE: string
  LANGUAGE: Record<string, any>
  VERSION: string
  VIDEO_PROVIDERS: Provider[]
  DefineIconTemplate: (name: string, template: string) => void

  $el: any
  browser: any
  edit: any
  events: any
  html: any
  image: any
  kundoFroala: any
  link: any
  opts: any
  position: any
  save: any
  selection: any
  (...args: any): any
}

// Scaffolding for easier local testing with Froala 4
//
// To test manually using the browser locally, activate the feature flag and reload:
//
//   ./manage.py toggle_feature_flags --enable --flag use-froala4
//
// To test using jest:
//
//   Change the top import from './froala-2' to './froala-4'
//
// Getting dynamic imports working in both webpack, vite and jest consistently
// is a bit difficult, so this is why we have this dual setup.
let importFroala4 = false
let importPromiseFroala4: undefined | Promise<any> = undefined
if (process.env.NODE_ENV === 'development') {
  importFroala4 = window.KUNDO.is_active('use-froala4') && FroalaEditorStatic.VERSION[0] === '2'
  if (importFroala4) {
    importPromiseFroala4 = import('./froala-4').then(({ FroalaEditor: FroalaEditor4 }) => {
      FroalaEditor = FroalaEditor4
      setupFroala()
      return FroalaEditor
    })
  }
}

export function loadFroala(callback: (froala: Froala) => void) {
  if (FroalaEditor) {
    callback(FroalaEditor)
    return
  }

  if (importPromiseFroala4) {
    importPromiseFroala4.then(() => {
      assertFroalaLoaded(FroalaEditor)
      callback(FroalaEditor)
    })
    return
  }

  FroalaEditor = FroalaEditorStatic
  setupFroala()
  assertFroalaLoaded(FroalaEditor)
  callback(FroalaEditor)
}

function setupFroala() {
  assertFroalaLoaded(FroalaEditor)
  FroalaEditor.LANGUAGE['kundo-lang'] = { translation: TRANSLATIONS.froala }
  FroalaEditor.VIDEO_PROVIDERS = VIDEO_PROVIDERS
  FroalaEditor.ICON_DEFAULT_TEMPLATE = 'kundo-fa'
  FroalaEditor.DefineIconTemplate('kundo-fa', '<i class="f-icon-[NAME]"></i>')

  registerCustomCommands(FroalaEditor)
  defineCustomIcons(FroalaEditor)
  froalaFixPasteBug(FroalaEditor)
  froalaMailHistory(FroalaEditor)
  froalaEmoji(FroalaEditor)
}

export function initFroalaEditor(kundoFroala: any, options: any) {
  assertFroalaLoaded(FroalaEditor)
  kundoFroala.usesFroala2 = FroalaEditor.VERSION[0] === '2'
  if (kundoFroala.usesFroala2) {
    kundoFroala.editor.froalaEditor(options)
    kundoFroala.froala = kundoFroala.editor.data('froala.editor')
    kundoFroala.onInitialized()
  } else {
    kundoFroala.froala = FroalaEditor(kundoFroala.editor[0], options)
  }
}

function assertFroalaLoaded(value: unknown): asserts value is Froala {
  if (value == null) {
    throw new Error('FroalaEditor library is not loaded')
  }
}
