import React, { useEffect, useMemo, useState } from 'react'
import { ReactComponent as IconX } from '@icons/x.svg'
import debounce from 'lodash/debounce'

type DebouncedTextInputProps = {
  debounceWaitTime: number
  onNewValue: (value: string) => void
  placeholder?: string
  resetButtonLabel?: string
}

export default function DebouncedTextInput(props: DebouncedTextInputProps) {
  const { debounceWaitTime, onNewValue: onNewValue, placeholder, resetButtonLabel } = props
  const [value, setValue] = useState<string>('')

  const debouncedResults = useMemo(() => {
    return debounce((value: string) => {
      onNewValue(value)
    }, debounceWaitTime)
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  useEffect(() => {
    debouncedResults(value)
  }, [value])

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onResetButtonClick = (e: React.MouseEvent) => {
    setValue('')
  }

  return (
    <>
      <input type="text" placeholder={placeholder} value={value} onChange={onInputChange} />
      {value.length > 0 && (
        <button type="reset" onClick={onResetButtonClick}>
          <IconX />
          <span className="visuallyhidden">{resetButtonLabel}</span>
        </button>
      )}
    </>
  )
}
