import { FroalaEditor } from 'jquery'

// Default Froala buttons and commands
export const BTN_BOLD = 'bold'
export const BTN_COLOR = 'color'
export const BTN_FORMAT_OL = 'formatOL'
export const BTN_FORMAT_UL = 'formatUL'
export const BTN_ITALIC = 'italic'
export const BTN_LINK = 'insertLink'
export const BTN_PARAGRAPH_FORMAT = 'paragraphFormat'
export const BTN_PARAGRAPH_STYLE = 'paragraphStyle'
export const BTN_QUOTE = 'quote'
export const BTN_SEPARATOR = '|'
export const BTN_TABLE = 'insertTable'
export const BTN_VIDEO = 'insertVideo'

// Custom Froala buttons and commands
export const BTN_CLEAR = 'kundoClearFormatting'
export const BTN_CONTACTS = 'kundoContactModal'
export const BTN_EMOJIS = 'emojis'
export const BTN_FILES = 'kundoFileUpload'
export const BTN_IMAGES = 'kundoImageUpload'
export const BTN_INTERNAL_LINKS = 'internalLink'
export const BTN_SNIPPETS = 'snippets'
export const BTN_SELF_SERVICE = 'selfService'

const _BUTTONS_ORDER = [
  BTN_PARAGRAPH_FORMAT,
  BTN_PARAGRAPH_STYLE,
  BTN_SEPARATOR,
  BTN_BOLD,
  BTN_ITALIC,
  BTN_QUOTE,
  BTN_CLEAR,
  BTN_COLOR,
  BTN_FORMAT_UL,
  BTN_FORMAT_OL,
  BTN_LINK,
  BTN_INTERNAL_LINKS,
  BTN_EMOJIS,
  BTN_TABLE,
  BTN_SNIPPETS,
  BTN_IMAGES,
  BTN_VIDEO,
  BTN_FILES,
  BTN_SELF_SERVICE,
  BTN_SEPARATOR,
  BTN_CONTACTS,
]

export function sortedToolbarButtons(btns: string[]) {
  const result: string[] = []
  for (let i = 0, len = _BUTTONS_ORDER.length; i < len; i++) {
    let btn = _BUTTONS_ORDER[i]
    if (btns.includes(btn)) {
      result.push(btn)
    }
    if (btn === BTN_SEPARATOR && result.length && result[result.length - 1] !== BTN_SEPARATOR) {
      result.push(btn)
    }
  }
  if (result.length && result[result.length - 1] === BTN_SEPARATOR) {
    result.pop()
  }
  return result
}

export enum ToolbarType {
  NONE = 'toolbar-none',
  CHAT = 'toolbar-chat',
  EXTERNAL_CONVERSATIONS = 'toolbar-external-conversations',
  FORUM = 'toolbar-forum',
  FORUM_EDITOR = 'toolbar-forum-editor',
  FORUM_TEXTS = 'toolbar-forum-texts',
  KNOWLEDGE = 'toolbar-knowledge',
  KNOWLEDGE_SETTINGS = 'toolbar-knowledge-settings',
  MAIL = 'toolbar-mail',
  MAIL_BASIC = 'toolbar-mail-basic',
  MAIL_REPLY_SNIPPET = 'toolbar-mail-reply-snippet',
  POLICY = 'toolbar-policy',
  SIMPLE = 'toolbar-simple',
  SIMPLE_CONTACT_MODAL = 'toolbar-simple-contact-modal',
}

// prettier-ignore
const _FROALA_BUTTONS_BASIC = [BTN_BOLD, BTN_ITALIC, BTN_QUOTE, BTN_CLEAR, BTN_FORMAT_UL, BTN_FORMAT_OL, BTN_LINK]
// prettier-ignore
const _FROALA_BUTTONS_WITH_COLOR = [BTN_BOLD, BTN_ITALIC, BTN_CLEAR, BTN_COLOR, BTN_FORMAT_UL, BTN_FORMAT_OL, BTN_LINK]
// prettier-ignore
const FROALA_TOOLBAR = {
  [ToolbarType.CHAT]: _FROALA_BUTTONS_WITH_COLOR.concat([BTN_EMOJIS, BTN_TABLE, BTN_PARAGRAPH_FORMAT]),
  [ToolbarType.EXTERNAL_CONVERSATIONS]: [BTN_BOLD, BTN_ITALIC, BTN_CLEAR, BTN_FORMAT_UL, BTN_FORMAT_OL, BTN_IMAGES, BTN_FILES],
  [ToolbarType.FORUM]: [BTN_IMAGES, BTN_FILES],
  [ToolbarType.FORUM_EDITOR]: _FROALA_BUTTONS_BASIC.concat([BTN_INTERNAL_LINKS, BTN_EMOJIS, BTN_SNIPPETS, BTN_IMAGES, BTN_VIDEO, BTN_FILES]),
  [ToolbarType.FORUM_TEXTS]: _FROALA_BUTTONS_BASIC,
  [ToolbarType.KNOWLEDGE]: _FROALA_BUTTONS_BASIC.concat([BTN_EMOJIS, BTN_TABLE, BTN_IMAGES, BTN_VIDEO, BTN_FILES, BTN_PARAGRAPH_FORMAT, BTN_PARAGRAPH_STYLE]),
  [ToolbarType.KNOWLEDGE_SETTINGS]: _FROALA_BUTTONS_WITH_COLOR,
  [ToolbarType.MAIL]: _FROALA_BUTTONS_WITH_COLOR.concat([BTN_INTERNAL_LINKS, BTN_EMOJIS, BTN_TABLE, BTN_SNIPPETS, BTN_IMAGES, BTN_FILES, BTN_SELF_SERVICE]),
  [ToolbarType.MAIL_BASIC]: _FROALA_BUTTONS_WITH_COLOR.concat([BTN_EMOJIS, BTN_IMAGES]),
  [ToolbarType.MAIL_REPLY_SNIPPET]: _FROALA_BUTTONS_WITH_COLOR.concat([BTN_EMOJIS, BTN_IMAGES, BTN_FILES]),
  [ToolbarType.NONE]: [],
  [ToolbarType.POLICY]: [BTN_PARAGRAPH_FORMAT].concat(_FROALA_BUTTONS_BASIC),
  [ToolbarType.SIMPLE]: [BTN_BOLD, BTN_ITALIC, BTN_LINK],
  [ToolbarType.SIMPLE_CONTACT_MODAL]: [BTN_BOLD, BTN_ITALIC, BTN_LINK, BTN_CONTACTS],
}

const SMALL_TOOLBAR = {
  [ToolbarType.EXTERNAL_CONVERSATIONS]: [BTN_BOLD, BTN_CLEAR],
}

const TOOLBAR_BOTTOM = {
  [ToolbarType.FORUM]: true,
}

const TOOLBAR_INSERTED = {
  [ToolbarType.FORUM]: true,
}

export function getToolbar(
  toolbarChoice: string,
  options: { includeInternalLinks: boolean; includeAI: boolean },
) {
  let buttons = sortedToolbarButtons(FROALA_TOOLBAR[toolbarChoice] || [])
  let lessButtons = sortedToolbarButtons(SMALL_TOOLBAR[toolbarChoice] || [])
  const toolbarBottom = TOOLBAR_BOTTOM[toolbarChoice]
  const toolbarInserted = TOOLBAR_INSERTED[toolbarChoice]

  if (!options.includeInternalLinks) {
    buttons = buttons.filter((e) => e !== BTN_INTERNAL_LINKS)
  }
  if (!options.includeAI) {
    buttons = buttons.filter((e) => e !== BTN_SELF_SERVICE)
  }
  return {
    bottom: !!toolbarBottom,
    buttons: buttons,
    hasColor: buttons.indexOf('color') > -1,
    inserted: !!toolbarInserted,
    lessButtons: lessButtons.length ? lessButtons : buttons,
  }
}

export function defineCustomIcons(FroalaEditor: any) {
  const addIcon = (name, data) => FroalaEditor.DefineIcon(name, { ...data })
  addIcon(BTN_CLEAR, { NAME: 'eraser' })
  addIcon(BTN_CONTACTS, { NAME: 'list-alt' })
  addIcon(BTN_EMOJIS, { FA5NAME: 'smile', NAME: 'smile-o' })
  addIcon(BTN_FILES, { NAME: 'clip2' })
  addIcon(BTN_IMAGES, { NAME: 'image' })
  addIcon(BTN_SNIPPETS, { NAME: 'book' })
  addIcon(BTN_INTERNAL_LINKS, { NAME: 'sitemap' })
  addIcon(BTN_PARAGRAPH_STYLE, { NAME: 'paragraph' })

  const twinkleAIIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" viewbox="2 2 40 20" fill="none"><path fill="currentColor" fill-rule="evenodd" d="M9.43 3.359c-.333-.844-1.527-.844-1.86 0L6.538 5.975a1 1 0 0 1-.563.563L3.359 7.57c-.844.333-.844 1.527 0 1.86l2.616 1.032a1 1 0 0 1 .563.563l1.032 2.616c.333.844 1.527.844 1.86 0l1.032-2.616a1 1 0 0 1 .563-.563l2.616-1.032c.844-.333.844-1.527 0-1.86l-2.616-1.032a1 1 0 0 1-.563-.563L9.43 3.359Zm8.5 10c-.333-.844-1.527-.845-1.86 0l-.608 1.54a1 1 0 0 1-.563.563l-1.54.608c-.844.333-.845 1.527 0 1.86l1.54.608a1 1 0 0 1 .563.563l.608 1.54c.333.844 1.527.844 1.86 0l.608-1.54a1 1 0 0 1 .563-.563l1.54-.608c.844-.333.844-1.527 0-1.86l-1.54-.608a1 1 0 0 1-.563-.563l-.608-1.54Z" clip-rule="evenodd"/><path fill="currentColor" d="M30.384 6h2.128l4.4 11.424h-2.144L33.696 14.4h-4.56l-1.072 3.024H26L30.384 6Zm-.704 6.88h3.488l-1.712-4.912h-.048L29.68 12.88ZM38 6h2v11.424h-2V6Z"/></svg>'

  FroalaEditor.DefineIcon(BTN_SELF_SERVICE, {
    NAME: '<span class="ai-fr-toolbar-btn-content"><i>' + twinkleAIIcon + '</i></span>',
    template: 'text',
  })
}
