import { useEffect, useRef, useState } from 'react'
import { match } from 'ts-pattern'

export enum StateType {
  Idle,
  Loading,
  Done,
  Error,
}

export type NetworkState<T> =
  | { type: StateType.Idle }
  | { type: StateType.Loading }
  | { type: StateType.Done; payload: T }
  | { type: StateType.Error; payload: any }

type Endpoint = string

type Endpoints = {
  editortools: {
    assign: (id: string) => Endpoint
    handled: (id: string) => Endpoint
    archived: (id: string) => Endpoint
    locked: (id: string) => Endpoint
    priority: (id: string) => Endpoint
    tags: (id: string) => Endpoint
    status: (id: string) => Endpoint
  }
  comments: {
    get: (id: string) => Endpoint
    create: (id: string) => Endpoint
    update: (id: string, comment: number) => Endpoint
    delete: (id: string, comment: number) => Endpoint
  }
  activity: {
    create: (slug: string, id: string) => Endpoint
    changeChannel: (org: number, id: string) => Endpoint
    channels: (org: number) => Endpoint
    dashboardItem: (org: number, id: string) => Endpoint
    delete: (slug: string, id: string) => Endpoint
    drafts: (org: number) => Endpoint
    user: (org: number, id: string) => Endpoint
  }
  user: {
    profile: (id: string) => Endpoint
    search: (org: number) => Endpoint
  }
  externalConversations: {
    conversations: (itemId: string) => Endpoint
    entries: (itemId: string, conversationId: string) => Endpoint
  }
  whatsapp: {
    conversations: (channelId: string, dialogSlug: string) => Endpoint
    templates: (channelId: string) => Endpoint
  }
  ai: {
    smartSuggestions: (
      inboxSlug: string,
      dialogSlug: string,
      mailCommentId: number | null,
    ) => Endpoint
    keywordSearch: (
      inboxSlug: string,
      dialogSlug: string,
      searchTerm: string,
      mailCommentId: number | null,
    ) => Endpoint
  }
}

export const endpoints: Endpoints = {
  editortools: {
    assign: (id) => `/dashboard/di/${id}/tools/assign_to/`,
    handled: (id) => `/dashboard/di/${id}/tools/no_reply_required/?field=no_reply_required`,
    archived: (id: string) => `/dashboard/di/${id}/tools/archived/?field=archived`,
    locked: (id: string) => `/dashboard/di/${id}/tools/locked/?field=locked`,
    priority: (id: string) => `/dashboard/di/${id}/tools/set_priority/`,
    tags: (id: string) => `/dashboard/di/${id}/tags/`,
    status: (id: string) => `/dashboard/di/${id}/tools/status/`,
  },
  comments: {
    get: (id: string) => `/dashboard/di/${id}/comments/`,
    create: (id: string) => `/dashboard/di/${id}/comments/create/`,
    update: (id: string, comment: number) => `/dashboard/di/${id}/comments/${comment}/`,
    delete: (id: string, comment: number) => `/dashboard/di/${id}/comments/${comment}/delete/`,
  },
  activity: {
    create: (slug, id) => `/activity/${slug}/a/${id}/draft/`,
    changeChannel: (org, id) => `/dashboard/${org}/activity/a/${id}/channel/`,
    channels: (org) => `/dashboard/${org}/activity/channels/`,
    dashboardItem: (org, id) => `/dashboard/${org}/activity/a/${id}/dashboard-item/`,
    delete: (slug, id) => `/activity/${slug}/a/${id}/delete/`,
    drafts: (org) => `/dashboard/${org}/activity/drafts/`,
    user: (org, id) => `/dashboard/${org}/activity/a/${id}/user/`,
  },
  user: {
    profile: (id) => `/profile/show/${id}/`,
    search: (org) => `/profile/search/${org}/`,
  },
  externalConversations: {
    conversations: (itemId) => `/dashboard/external-conversations/${itemId}/`,
    entries: (itemId, conversationId) =>
      `/dashboard/external-conversations/${itemId}/${conversationId}/`,
  },
  whatsapp: {
    conversations: (channelId, dialogSlug) => `/whatsapp/${channelId}/${dialogSlug}/`,
    templates: (channelId) => `/whatsapp/${channelId}/templates/`,
  },
  ai: {
    smartSuggestions: (inboxSlug, dialogSlug, mailCommentId) =>
      `/smart-replies/inbox/${inboxSlug}/relevant-suggestions/${dialogSlug}?mailCommentId=${mailCommentId}`,
    keywordSearch: (inboxSlug, dialogSlug, searchTerm, mailCommentId) =>
      `/smart-replies/inbox/${inboxSlug}/search-suggestions/${dialogSlug}/${searchTerm}?mailCommentId=${mailCommentId}`,
  },
}

export enum HTTP {
  GET,
  PUT,
  POST,
  DELETE,
}

export type RequestFn<T, U> = (data?: U) => Promise<T>

export default function useApi<T, U = {}>(
  verb: HTTP,
  endpoint: Endpoint,
): [NetworkState<T>, RequestFn<T, U>] {
  const isUnmounted = useRef(false)
  const dataType = 'json'

  useEffect(() => {
    isUnmounted.current = false

    return () => {
      isUnmounted.current = true
    }
  }, [])

  const [networkState, setNetworkState] = useState<NetworkState<T>>({
    type: StateType.Idle,
  })

  const handleResponse = (promise: Promise<T>) => {
    if (isUnmounted.current) return promise
    setNetworkState({ type: StateType.Loading })

    return promise
      .then((payload: T) => {
        if (isUnmounted.current) return
        setNetworkState({ type: StateType.Done, payload })
        return payload
      })
      .catch((x) => {
        if (isUnmounted.current) return
        setNetworkState({ type: StateType.Error, payload: x })
        return x
      })
  }

  const request = match<HTTP, RequestFn<T, U>>(verb)
    .with(
      HTTP.GET,
      () => (): Promise<T> =>
        handleResponse(
          new Promise<T>((resolve, reject) =>
            $.get({ url: endpoint, dataType }).then(resolve).catch(reject),
          ),
        ),
    )
    .with(
      HTTP.POST,
      () => (data: U) =>
        handleResponse(
          new Promise<T>((resolve, reject) =>
            $.post({ url: endpoint, data, dataType }).then(resolve).catch(reject),
          ),
        ),
    )
    .with(
      HTTP.PUT,
      () => (data: U) =>
        handleResponse(
          new Promise<T>((resolve, reject) =>
            $.ajax({ url: endpoint, type: 'PUT', data: JSON.stringify(data) })
              .then(resolve)
              .catch(reject),
          ),
        ),
    )
    .with(
      HTTP.DELETE,
      () => () =>
        handleResponse(
          new Promise<T>((resolve, reject) =>
            $.ajax({ url: endpoint, type: 'DELETE' }).then(resolve).catch(reject),
          ),
        ),
    )
    .run()

  return [networkState, request]
}

export const request = <T, U = {}>({
  url,
  type,
  data,
}: {
  url: string
  type: 'GET' | 'POST' | 'PUT' | 'DELETE'
  data?: U
}): Promise<T> => {
  return new Promise((resolve, reject) => $.ajax({ url, type, data }).then(resolve).catch(reject))
}
