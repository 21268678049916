import qq from 'fine-uploader/lib/core'
import { hasIntersection } from 'vega-lite'

const { UPLOADS_MAX_SIZE, UPLOADS_MAX_SIZE_MAIL } = window

function scrollContentToBottom() {
  $('html, body').animate(
    {
      scrollTop: $('html, body').get(0).scrollHeight,
    },
    300,
  )
}

export function uploadImageAsUpload(
  kundoFroala,
  files,
  allowedExtensions,
  isMailContext,
  allowLargeUploads,
) {
  // Calls the upload_file view to create a Upload image
  const { attachmentsList, uploadsUrl } = kundoFroala
  const onSuccess = (file) => {
    kundoFroala.froala.image.insert(file.temporaryUrl)
  }
  const disposition = 'inline'
  const uploader = uploadsServiceUploaderFactory(
    uploadsUrl,
    attachmentsList,
    allowedExtensions,
    onSuccess,
    isMailContext,
    allowLargeUploads,
    disposition,
  )
  uploader.addFiles(files)
}

export function uploaderFactory(
  url,
  attachmentsList,
  isMailContext,
  allowLargeUploads,
  allowedExtensions = [],
) {
  // Sample "attachments" response
  // { "success": true, "id": 12, "disposition": "inline", "file_url": "/site_media_p/attachment/context_1/file name.ext" }
  const onComplete = async (id, name, responseJSON, xhr) => {
    attachmentsList.hide_progress()
    if (responseJSON.success) {
      await attachmentsList.add_file(responseJSON)
    } else {
      await attachmentsList.show_error_msg(responseJSON.error)
    }

    const frame = $('#mail-iframe', window.parent.document)
    frame.length > 0 && frame.height(document.body.scrollHeight)

    scrollContentToBottom()
  }
  return baseUploaderFactory(
    url,
    attachmentsList,
    allowedExtensions,
    onComplete,
    isMailContext,
    allowLargeUploads,
  )
}

function uploadsServiceUploaderFactory(
  url,
  attachmentsList,
  allowedExtensions,
  onSuccess,
  isMailContext = false,
  allowLargeUploads = false,
  disposition = 'attachment',
) {
  // Sample "uploads" response
  // {"success": true, "type": "jpeg", "temporary_url": "https://something"}
  const onComplete = (id, name, responseJSON, xhr) => {
    attachmentsList.hide_progress()
    if (!responseJSON.error) {
      const file = {
        id: responseJSON.path,
        puri: responseJSON.puri,
        url: responseJSON.temporary_url,
        temporaryUrl: responseJSON.temporary_url,
        disposition: 'inline',
      }
      attachmentsList.refresh_attachments()
      onSuccess(file)
    } else {
      attachmentsList.show_error_msg(responseJSON.error)
    }
  }
  return baseUploaderFactory(
    url,
    attachmentsList,
    allowedExtensions,
    onComplete,
    isMailContext,
    allowLargeUploads,
    disposition,
  )
}

function baseUploaderFactory(
  url,
  attachmentsList,
  allowedExtensions,
  onComplete,
  isMailContext = false,
  allowLargeUploads = false,
  disposition = 'attachment',
) {
  return new qq.FineUploaderBasic({
    request: {
      endpoint: url,
      inputName: 'file',
      customHeaders: {
        'X-Is-Mail-Context': isMailContext ? '1' : '0',
        'X-Allow-Large-Uploads': allowLargeUploads ? '1' : '0',
      },
    },
    retry: {
      enableAuto: true,
      autoAttemptDelay: 1,
      maxAutoAttempts: 1,
    },
    validation: {
      sizeLimit: isMailContext ? UPLOADS_MAX_SIZE_MAIL : UPLOADS_MAX_SIZE,
      allowedExtensions,
    },
    messages: {
      typeError: TRANSLATIONS.file_upload_invalid_extension,
      emptyError: TRANSLATIONS.file_upload_empty,
      noFilesError: TRANSLATIONS.file_upload_no_files,
      sizeError: TRANSLATIONS.file_upload_size_error,
    },
    callbacks: {
      onComplete,
      onSubmit() {
        attachmentsList.show_progress(0, disposition)
      },
      onProgress(id, name, uploadedBytes, totalBytes) {
        const percent = (uploadedBytes / totalBytes) * 100
        attachmentsList.show_progress(percent, disposition)

        scrollContentToBottom()
      },
      onAutoRetry(id, name) {
        const msg = TRANSLATIONS.file_upload_retrying
        attachmentsList.show_error_msg(msg)
      },
      onError(id, name, errorReason, xhr) {
        if (xhr && xhr.status === 413) {
          // this.cancel(id) // TODO: FineUploader throws an error if we cancel this, see: https://github.com/FineUploader/fine-uploader/issues/1758
          const msg = TRANSLATIONS.file_upload_size_error
            .replace('{file}', name)
            .replace(
              '{sizeLimit}',
              this._formatSize(isMailContext ? UPLOADS_MAX_SIZE_MAIL : UPLOADS_MAX_SIZE),
            )
          attachmentsList.hide_progress()
          attachmentsList.show_error_msg(msg)
          return
        }
        attachmentsList.show_error_msg(errorReason)
      },
    },
  })
}
