export default function skeletonLoadingBoxes() {
  return `
    <div class="smart-replies-froala-content--loader">
      <span class="skeleton-box" style="width: 20%; max-width: 80px;"></span>
      <div class="fill-row"></div>
      <span class="skeleton-box" style="width: 40%; max-width: 160px;"></span>
      <span class="skeleton-box" style="width: 100%; max-width: 400px;"></span>
      <span class="skeleton-box" style="width: 100%; max-width: 400px;"></span>
      <span class="skeleton-box" style="width: 90%; max-width: 360px;"></span>
      <div class="fill-row"></div>
      <span class="skeleton-box" style="width: 70%; max-width: 280px; display: block; margin-bottom: 0.25em;"></span>
      <span class="skeleton-box" style="width: 70%; max-width: 280px; display: block; margin-bottom: 0.25em;"></span>
      <div style="width: 100%; height: 1em;"></div>
      <div style="width: 100%; height: 1em;"></div>
      <span class="skeleton-box" style="width: 35%; max-width: 140px;"></span>
    </div>`
}
