export default class InternalLinkWidget {
  box: JQuery
  selected: JQuery | null
  searchBox: JQuery
  resultList: JQuery
  url: string
  onselect: Function
  onclose: Function

  constructor(options: { box: JQuery; onselect?: Function; onclose?: Function }) {
    this.selected = null
    this.box = options.box
    this.searchBox = this.box.find('.fn-searchbox')
    this.resultList = this.box.find('.result-list ul')
    this.searchBox.on('keyup', this.doSearch.bind(this))
    this.box.on('keypress', (e: JQuery.KeyPressEvent) => {
      if (e.key !== 'Enter') {
        return
      }
      e.preventDefault()
      this.selected && this.selectItem()
    })
    this.onselect = options.onselect || function () {}
    this.onclose = options.onclose || function () {}

    $('body').on('click', () => this.close(false))
    this.box.on('click', (e) => e.stopPropagation())

    this.box.find('.fn-cancel').on('click', (e) => this.close(false))
    this.box.find('.fn-add-link').on('click', this.selectItem.bind(this))
    this.resultList.on('click', 'li', this.onLinkClick.bind(this))
    this.url = this.box.data('ajax-url')
  }

  onLinkClick(e) {
    let target = e.target
    if (e.target.tagName === 'SPAN') {
      target = e.target.parentElement
    }
    this.selected = $(target)
    // @ts-expect-error
    for (let child of this.resultList.children()) {
      $(child).removeClass('selected')
    }
    this.selected.addClass('selected')
  }

  selectItem() {
    if (this.selected) {
      const link = this.selected.data('url')
      const label = this.selected.find('.fn-forum-link')[0].innerText
      this.onselect(this, label, link)
    }
    this.close()
  }

  doSearch() {
    this.search(<string>this.searchBox.val())
  }

  search(query: string) {
    $.ajax({
      url: this.url,
      data: { q: query },
      success: this.handleSearchResponse.bind(this),
    })
  }

  handleSearchResponse(data) {
    const result: JQuery<HTMLElement>[] = []
    data.forEach((hit) => {
      const $item = $('<li>', { 'data-url': hit.url })
      const $link = $('<a>', { href: hit.url, target: '_blank' })
      $link.append($('<span>', { text: hit.url, class: 'visuallyhidden' }))
      $item.append($link)
      $item.append($('<span>', { html: hit.title, class: 'fn-forum-link forum-link' }))
      result.push($item)
    })

    this.resultList.empty().append(result)
  }

  open() {
    this.box.removeClass('hidden')
    this.searchBox.trigger('focus')
    return this
  }

  close(focus = true) {
    this.box.addClass('hidden')
    this.onclose(this, focus)
    return this
  }

  toggle() {
    if (this.box.is('.hidden')) {
      this.doSearch()
      return this.open()
    } else {
      return this.close()
    }
  }
}
