import React, { useEffect, useState, useRef } from 'react'
import { createRoot } from 'react-dom/client'

type SnackbarMessage = { message: string; link?: { url: string; text: string } }

const showEvent = 'kundo--snackbar:show'

export const triggerSnackbar = (
  message: SnackbarMessage['message'],
  link?: SnackbarMessage['link'],
): void => {
  document.dispatchEvent(
    new CustomEvent(showEvent, {
      detail: { message, link },
    }),
  )
}

export default function Snackbar() {
  const snackRef = useRef<HTMLDivElement | null>(null)
  const [activeMessage, setActiveMessage] = useState<SnackbarMessage | null>(null)
  const queue = useRef<SnackbarMessage[]>([])

  useEffect(() => {
    const listener = ({ detail }: CustomEvent<SnackbarMessage>) => {
      if (activeMessage) {
        queue.current.push(detail)
      } else {
        setActiveMessage(detail)
      }
    }
    document.addEventListener(showEvent, listener)

    return () => document.removeEventListener(showEvent, listener)
  }, [activeMessage])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (queue.current.length) {
        const first = queue.current[0]
        queue.current = queue.current.slice(1)
        setActiveMessage(first)
      } else {
        setActiveMessage(null)
      }
    }, 8000)
    return () => clearTimeout(timer)
  }, [activeMessage])

  const calculatePosition = (scrollHeight: number) => {
    if (snackRef.current) {
      if (scrollHeight >= 64) {
        snackRef.current.style.position = 'fixed'
        snackRef.current.style.top = '8px'
      } else {
        snackRef.current.style.position = 'absolute'
        snackRef.current.style.top = '66px'
      }
    }
  }

  useEffect(() => {
    const listener = (e: any) => calculatePosition(e.target?.scrollingElement.scrollTop)

    document.addEventListener('scroll', listener)

    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [])

  const initSnackRef = (el: HTMLDivElement) => {
    snackRef.current = el
    calculatePosition(window.scrollY)
  }

  return (
    activeMessage && (
      <div ref={initSnackRef} className="snackbar" aria-live="polite">
        <div className="snackbar-message">{activeMessage.message}</div>
        {activeMessage.link && (
          <div className="snackbar-button btn-wrapper btn-wrapper--justify-right">
            <a
              href={activeMessage.link.url}
              className="btn btn--small btn--tertiary btn--tertiary-small"
            >
              {activeMessage.link.text}
            </a>
          </div>
        )}
      </div>
    )
  )
}

export const initSnackbar = () => {
  const container = $('<div id="snackbar-container"></div>')
  container.appendTo(document.body)
  const root = createRoot(container[0])
  root.render(<Snackbar />)

  const flashMessages = Array.from(document.querySelectorAll('[data-fn-snackbar-message]'))
  setTimeout(() => {
    flashMessages.forEach((el) => {
      const message = el.getAttribute('data-message-content')
      const message_type = el.getAttribute('data-message-type')
      if (message && message_type === 'info') triggerSnackbar(message)
    })
  }, 0)
}
