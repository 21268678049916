import IconSearch from '@icons/search.svg'

type OnSelectHandler = (data: { text: string; attachments: any[] }) => void

export type Snippet = {
  id: number
  text: string
  name: string
  updated_at: string
  usage_url: string
  attachments: {}[]
  excerpt: string
}
export default class SnippetWidget {
  box: JQuery<HTMLElement>
  firstSnippet: JQuery<HTMLLIElement>
  search: JQuery<HTMLElement>
  snippet_box: JQuery<HTMLElement>
  snippets: JQuery<HTMLLIElement>

  prev_search_term: string

  onclose: (focus: boolean) => void
  onselect: OnSelectHandler

  constructor(options: {
    url: string
    parent: HTMLElement
    onselect: OnSelectHandler
    onclose?: (focus: boolean) => void
    positionAbove?: boolean
  }) {
    this.renderInitial(options.positionAbove)
    this.box.appendTo(options.parent)

    this.onselect = options.onselect || function () {}
    this.onclose = options.onclose || function () {}
    this.snippets = this.snippet_box.find('li')
    this.firstSnippet = this.snippets.filter('.selected')
    this.prev_search_term = ''

    $('body').on('click', () => this.close(false))
    this.box.on('click', (e) => {
      e.stopPropagation()
      this.search.trigger('focus')
    })

    // Setup events for everything...
    this.search.on('keypress', (e) => this.selectEnter(e))
    this.search.on('keydown', (e) => this.keyboardNavigation(e))
    this.search.on('keyup input', () => this.filterSnippet())

    this.fetchSnippets(options.url)
  }

  renderInitial(positionAbove?: boolean) {
    let inputId = 'snippet-search'
    let labelText = TRANSLATIONS.snippet_label_msg
    let label = $(`<label class="visuallyhidden" for="snippet-search">`)
      .attr('for', inputId)
      .text(labelText)
    this.search = $(`<input type="search" autocomplete="off" class="ml-2 w-full">`)
      .attr('placeholder', labelText)
      .attr('name', inputId)
      .attr('id', inputId)

    this.box = $(`<div class="hidden snippet-box">`)
      .addClass(positionAbove ? 'snippet-box--above' : 'snippet-box--below')
      .append(
        $(`<h2>`).text(TRANSLATIONS.snippet_heading),
        $(`<div class="search-wrapper flex items-center">`).append(IconSearch, this.search, label),
      )

    this.snippet_box = $(`<ul class="list-unstyled snippet-box-options">`).appendTo(this.box)
  }

  async fetchSnippets(url: string) {
    const data: { snippets: Snippet[] } = await $.ajax(url)
    data.snippets.forEach((snippet, i) => {
      $(`<li class="snippet-box__item fn-tooltip-veryslow">`)
        .attr('title', snippet.name + ' - ' + snippet.excerpt)
        .toggleClass('selected', i === 0)
        .data('id', snippet.id)
        .data('text', snippet.text)
        .data('attachments', snippet.attachments)
        .data('usage-url', snippet.usage_url)
        .append($(`<span class="snippet-box__name">`).text(snippet.name))
        .append($(`<span class="subtle">`).text(snippet.updated_at))
        .appendTo(this.snippet_box)
    })

    this.snippets = this.snippet_box.find('li')
    this.snippets.on('mouseover', (e) => this.hoverSnippet(e))
    this.snippets.on('click', (e) => this.selectClick(e))
    this.firstSnippet = this.snippets.filter('.selected')
    KUNDO.tooltip?.update()
  }

  hoverSnippet(e: JQuery.MouseOverEvent) {
    const selected = this.snippets.filter('.selected')

    const new_selected = $(e.currentTarget)
    this.moveSelected(selected, new_selected, false)
  }

  selectClick(e: JQuery.ClickEvent) {
    const selected = $(e.currentTarget)
    this.select(selected)
    this.close()
  }

  select(selected: JQuery) {
    this.search.val('')
    selected.removeClass('selected')
    this.firstSnippet.addClass('selected')
    this.filterSnippet()
    if (!selected.length) {
      return
    }
    this.onselect({
      text: selected.data('text'),
      attachments: selected.data('attachments'),
    })
    $.post(selected.data('usage-url'))
  }

  selectEnter(e: JQuery.KeyPressEvent) {
    if (e.which === 13) {
      // Enter
      e.preventDefault()
      const selected = this.snippets.filter('.selected')
      this.select(selected)
      this.close()
    }
  }

  keyboardNavigation(e: JQuery.KeyDownEvent) {
    let new_selected: JQuery
    const selected = this.snippets.filter('.selected')

    if (e.which === 27) {
      // Escape
      e.stopPropagation()
      return this.close()
    } else if (e.which === 40) {
      // Down
      new_selected = selected.nextAll().filter(':visible:first')
      if (new_selected.length) {
        this.moveSelected(selected, new_selected)
        return e.preventDefault()
      }
    } else if (e.which === 38) {
      // Up
      new_selected = selected.prevAll().filter(':visible:first')
      if (new_selected.length) {
        this.moveSelected(selected, new_selected)
        return e.preventDefault()
      }
    }
  }

  filterSnippet() {
    const search_term = this.search.val() as string
    if (search_term === this.prev_search_term) {
      return
    }

    this.prev_search_term = search_term
    this.snippets.each((_, el) => {
      const snippet = $(el)
      if (fuzzyMatches(search_term, snippet.text())) {
        snippet.removeClass('hidden')
      } else {
        snippet.addClass('hidden')
      }
    })

    const selected = this.snippets.filter('.selected')
    const new_selected = this.snippets.filter(':visible:first')
    this.moveSelectedScrollTop(selected, new_selected)
  }

  moveSelected(from: JQuery, to: JQuery, focus = true) {
    from.removeClass('selected')
    to.addClass('selected')
    if (focus) {
      this.scrollToElement(from, to)
    }
  }

  moveSelectedScrollTop(from: JQuery, to: JQuery) {
    from.removeClass('selected')
    to.addClass('selected')
    this.snippet_box.scrollTop(0)
  }

  getScrollToDirection(from: JQuery, to: JQuery) {
    const amountOfElementsAboveFromElement = from.prevAll().filter(':visible').length
    const amountOfElementsAboveToElement = to.prevAll().filter(':visible').length

    const shouldScrollDownwards = amountOfElementsAboveToElement > amountOfElementsAboveFromElement
    const shouldScrollUpwards = amountOfElementsAboveToElement < amountOfElementsAboveFromElement
    return { shouldScrollDownwards, shouldScrollUpwards }
  }

  scrollToElement(from: JQuery, to: JQuery) {
    const currentScrollPosition = this.snippet_box.scrollTop()

    if (currentScrollPosition === undefined) {
      return
    }

    const { shouldScrollDownwards, shouldScrollUpwards } = this.getScrollToDirection(from, to)

    if (shouldScrollDownwards) {
      const scrollAmount = from.outerHeight()
      if (scrollAmount) {
        this.snippet_box.scrollTop(currentScrollPosition + scrollAmount)
      }
    } else if (shouldScrollUpwards) {
      const scrollAmount = to.outerHeight()
      if (scrollAmount) {
        this.snippet_box.scrollTop(currentScrollPosition - scrollAmount)
      }
    }
  }

  open() {
    this.box.removeClass('hidden')
    this.search.trigger('focus')
    return this
  }

  close(focus = true) {
    this.box.addClass('hidden')
    this.onclose(focus)
    return this
  }

  toggle() {
    if (this.box.is('.hidden')) {
      return this.open()
    } else {
      return this.close()
    }
  }

  destroy() {
    this.box.remove()
  }
}

function fuzzyMatches(search_term: string, string: string) {
  search_term = search_term.toLowerCase()
  string = string.toLowerCase()
  let string_index = 0
  for (let search_index = 0, end = search_term.length; search_index < end; search_index++) {
    while (string[string_index] !== search_term[search_index]) {
      string_index++
      if (string_index > string.length) {
        return false
      }
    }
  }
  return true
}
