import React, { useEffect, useState } from 'react'
import SuggestionCard, { SuggestionCardProps } from './SuggestionCard'

type SuggestionCardListProps = {
  suggestions: SuggestionCardProps[]
  onRequestInsertLink: (title: string, link: string) => void
  onRequestSmartReply: (url: string) => void
}

export default function SuggestionCardList(props: SuggestionCardListProps) {
  const { suggestions, onRequestSmartReply, onRequestInsertLink } = props

  return (
    <>
      <ul className="suggestion-card-list">
        {suggestions.map((suggestion, index) => (
          <li key={index} className="suggestion-card-list--item">
            <SuggestionCard
              channelColorClass={suggestion.channelColorClass}
              channelIconStyling={suggestion.channelIconStyling}
              channelName={suggestion.channelName}
              distance={suggestion.distance}
              excerpt={suggestion.excerpt}
              onRequestInsertLink={onRequestInsertLink}
              onRequestSmartReply={onRequestSmartReply}
              publicUrl={suggestion.publicUrl}
              smartReplyPath={suggestion.smartReplyPath}
              title={suggestion.title}
            />
          </li>
        ))}
      </ul>
    </>
  )
}
