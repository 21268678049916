import React from 'react'

import { ReactComponent as ArrowRightUp } from '@icons/arrow-right-up.svg'
import { ReactComponent as IconTwinkle } from '@icons/twinkle.svg'

export type SuggestionCardProps = {
  channelColorClass: string
  channelIconStyling: string
  channelName: string
  distance: number | null
  excerpt: string
  onRequestInsertLink: (title: string, url: string) => void
  onRequestSmartReply: (url: string) => void
  publicUrl: string
  smartReplyPath: string
  title: string
}

export default function SuggestionCard(props: SuggestionCardProps) {
  const {
    channelColorClass,
    channelIconStyling,
    channelName,
    distance,
    excerpt,
    onRequestInsertLink,
    onRequestSmartReply,
    publicUrl,
    smartReplyPath,
    title,
  } = props

  const requestInsertLink = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    onRequestInsertLink(title, publicUrl)
  }

  const requestSmartReply = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    onRequestSmartReply(smartReplyPath)
  }

  return (
    <div className="suggestion-card">
      <div className="suggestion-card__header">
        <a href={publicUrl} target="_blank" rel="nofollow noopener">
          <h3 className="suggestion-card__title" data-distance={distance}>
            <i
              className={'channel-icon ' + channelColorClass}
              dangerouslySetInnerHTML={{ __html: channelIconStyling }}
              title={channelName}
            />
            <span className="suggestion-title">{title}</span>
            <ArrowRightUp className="external-link-icon"></ArrowRightUp>
          </h3>
        </a>
      </div>
      <div className="suggestion-card__body">
        <p>{excerpt}</p>
      </div>
      <div className="suggestion-card__footer">
        <button
          id="smart-reply"
          title="Smart Reply"
          className="btn btn--editor btn--secondary btn--small"
          onClick={requestSmartReply}
        >
          <IconTwinkle></IconTwinkle> Smart Reply
        </button>
        <button
          id="link-to-guide"
          title={gettext('Infoga länk')}
          className="btn btn--editor btn--tertiary btn--small align-left"
          onClick={requestInsertLink}
        >
          {gettext('Infoga länk')}
        </button>
      </div>
    </div>
  )
}
