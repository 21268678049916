function getFiletypeFromName(filenameIncludingExtension = '') {
  const extension = filenameIncludingExtension.split('.').pop()!.toLowerCase()
  const definedFiletypes = {
    pdf: 'pdf',
    word: 'word',
    excel: 'excel',
    image: 'image',
  }
  const mappings = {
    pdf: definedFiletypes.pdf,
    doc: definedFiletypes.word,
    docx: definedFiletypes.word,
    xls: definedFiletypes.excel,
    xlsx: definedFiletypes.excel,
    jpeg: definedFiletypes.image,
    jpg: definedFiletypes.image,
    jpe: definedFiletypes.image,
    png: definedFiletypes.image,
    gif: definedFiletypes.image,
    tif: definedFiletypes.image,
    tiff: definedFiletypes.image,
  }
  return mappings[extension]
}

export default getFiletypeFromName
