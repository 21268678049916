import froalaSetup from 'vendor/froala2/froala_editor.min.js'

import froalaColor from 'vendor/froala2/colors.min.js'
import froalaDraggable from 'vendor/froala2/draggable.min.js'
import froalaImage from 'vendor/froala2/image.min.js'
import froalaLink from 'vendor/froala2/link.min.js'
import froalaList from 'vendor/froala2/lists.min.js'
import froalaParagraphFormat from 'vendor/froala2/paragraph_format.min.js'
import froalaParagraphStyle from 'vendor/froala2/paragraph_style.min.js'
import froalaQuote from 'vendor/froala2/quote.min.js'
import froalaSave from 'vendor/froala2/save.min.js'
import froalaTable from 'vendor/froala2/table.min.js'
import froalaUrl from 'vendor/froala2/url.min.js'
import froalaVideo from 'vendor/froala2/video.min.js'

if (typeof froalaSetup === 'function') {
  const froalaSetupFunctions = [
    froalaSetup,
    froalaSave,
    froalaImage,
    froalaList,
    froalaDraggable,
    froalaLink,
    froalaTable,
    froalaUrl,
    froalaParagraphFormat,
    froalaParagraphStyle,
    froalaVideo,
    froalaQuote,
    froalaColor,
  ]
  froalaSetupFunctions
    .filter((func) => typeof func === 'function')
    .forEach((func) => {
      try {
        func(window, $)
      } catch (e) {
        console.warn('FIXME(ch15922): Froala 4 error: ' + e)
      }
    })
}

export const FroalaEditor = $.FroalaEditor
