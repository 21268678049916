export type Provider = {
  html: string
  provider: string
  test_regex: RegExp
  url_regex: RegExp
  url_text: string
}

export const VIDEO_PROVIDERS: Provider[] = [
  {
    html: '<iframe src="{url}?modestbranding=1&amp;rel=0" allowfullscreen></iframe>',
    provider: 'YouTube',
    test_regex:
      /^.*((youtu.be)|(youtube.com))\/((v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))?\??v?=?([^#&?]*).*/,
    url_regex:
      /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/)?([0-9a-zA-Z_-]+)(.+)?/g,
    url_text: 'https://www.youtube-nocookie.com/embed/$1',
  },
  // Vimeo id-only regex, e.g. https://vimeo.com/123456/abcdef
  {
    html: '<iframe src="{url}" allowfullscreen></iframe>',
    provider: 'Vimeo',
    test_regex: /^.*(vimeo.com)\/(\w+)\/(\w+)(?:|\/\?)/,
    url_regex: /(?:http|https)?:\/\/(?:www\.)?vimeo.com\/(\w+)\/(\w+)(?:|\/\?)(?:|\/\?)/i,
    url_text: 'https://player.vimeo.com/video/$1?h=$2',
  },
  {
    html: '<iframe src="{url}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0" allowfullscreen></iframe>',
    provider: 'Vimeo',
    test_regex:
      /^.*(vimeo.com)\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|)(\d+)(?:|\/\?)/,
    url_regex:
      /(?:http|https)?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|)(\d+)(?:|\/\?)/i,
    url_text: 'https://player.vimeo.com/video/$1',
  },
  {
    html: '<iframe src="{url}" allowfullscreen></iframe>',
    provider: 'SVT',
    test_regex: /^.*((svtplay\.se)|(svt\.se))\/(klipp\/|videoplayer-embed\/(?:(?:nojs\/)?))(\d+)/, //(?:|\/\?)/,
    url_regex:
      /(?:http|https)?:\/\/(?:(?:www\.)|(?:api\.))?(?:(?:svtplay\.se)|(?:svt\.se))\/(?:klipp\/|videoplayer-embed\/(?:(?:nojs\/)?))(\d+)(?:.+)?/i,
    url_text: 'https://svt.se/videoplayer-embed/$1',
  },
  {
    html: '<iframe src="{url}" allowfullscreen></iframe>',
    provider: 'DreamBroker',
    test_regex: /dreambroker.com/,
    url_regex: /(?:https?:\/\/)?(?:www\.)?dreambroker.com\/channel\/(\w+)\/(\w+)/i,
    url_text: 'https://www.dreambroker.com/channel/$1/iframe/$2',
  },
]
