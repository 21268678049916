import { retryFunction } from 'platform/utils'
import { LargeAttachmentsList } from './attachmentslist'
import KundoFroala2Editor, { getKundoFroalaInstance } from './kundo-froala-editor'

export const Formatting = {
  init() {
    setupTextEditors()
    setupShortcuts()
  },

  isTextEditorReady(elm: HTMLElement | JQuery): boolean {
    return !!getKundoFroalaInstance(elm)?.isInitialized
  },

  waitForTextEditorReady(elm: HTMLElement | JQuery): Promise<void> {
    return new Promise((resolve) => {
      retryFunction(() => {
        const editor = getKundoFroalaInstance(elm)
        if (editor) {
          if (editor.isInitialized) {
            resolve()
          } else {
            editor.on('kundoFroalaInitialized', resolve)
          }
        } else {
          throw new Error('Found no editor')
        }
      })
    })
  },

  getTextEditorHTML(elm: HTMLElement | JQuery): string {
    return getKundoFroalaInstance(elm)?.getHTML() || ''
  },

  setTextEditorHTML(elm: HTMLElement | JQuery, html: null | string) {
    getKundoFroalaInstance(elm)?.setHTML(html)
  },

  getTextEditorCaretPosition(elm: HTMLElement | JQuery) {
    return getKundoFroalaInstance(elm)?.froala.position.getBoundingRect()
  },

  focusTextEditor(elm: HTMLElement | JQuery) {
    getKundoFroalaInstance(elm)?.focus()
  },

  forceTextEditorAutosave(elm: HTMLElement | JQuery) {
    getKundoFroalaInstance(elm)?.setDirty()
  },
}

window.KUNDO.formatting = Formatting

function handleKeyDown(event: JQuery.KeyDownEvent) {
  if ((event.metaKey || event.ctrlKey) && event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault()
    event.stopPropagation()
    $(event.target).parents('form').submit()
    return false
  }
}

function setupShortcuts() {
  $('.fn-submit-shortcut').on('keydown', handleKeyDown)
  $('.fn-text-editor textarea').each((_, elm) => {
    getKundoFroalaInstance(elm)?.on('keydown', handleKeyDown, true)
  })
}

function setupTextEditors() {
  // If no text editor exists, we don't need to do anything ...
  if ($('.fn-text-editor').length === 0) {
    return
  }

  // ... but it's not enough to just find the textareas, since we need to connect
  // the attachment ids with the toolbar
  $('form').each((_i, form) => setupTextEditorForm($(form)))
}

function setupTextEditorForm(form: JQuery) {
  form.find('.fn-text-editor').each((_, elem) => {
    if (getKundoFroalaInstance(elem)) {
      return
    }
    const attachmentsElm = form.find('.fn-attachments-large')

    const parentObj = (form.find('#id_cmt-parent_object_slug').val() || '') as string
    const isMailContext = parentObj.startsWith('mail') || form.find('#id_inbox').length > 0
    const allowLargeUploads = elem.dataset.hasOwnProperty('fnAllowLargeUploads')

    let attachmentsList: undefined | LargeAttachmentsList = undefined
    if (attachmentsElm.length) {
      attachmentsList = new LargeAttachmentsList(attachmentsElm)
      attachmentsList.refresh_attachments()
    }
    new KundoFroala2Editor($(elem), attachmentsList, isMailContext, allowLargeUploads)
  })
}
